@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.animate-spin  {
  animation: spin 3s linear infinite !important;
}


.curve-corner {
  background:
  radial-gradient(circle at 0 0, rgba(204,0,0,0) 0px, #fff4ed 15px),
  radial-gradient(circle at 100% 100%, #cc000000 0, hsl(23, 100%, 96%) 0),
  radial-gradient(circle at 100% 0, rgba(204,0,0,0) 25px, #fff4ed 15px),
  radial-gradient(circle at 0 0, rgba(204,0,0,0) 25px, #fff4ed 15px);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  border-top: dashed 1px #e8bc99;
}

.curve-corner-down {
  background:
  radial-gradient(circle at 0 100%, rgba(204,0,0,0) 25px, #fff4ed 15px),
  radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 25px, #fff4ed 15px),
  radial-gradient(circle at 100% 0, rgba(204,0,0,0) 0px, #fff4ed 0),
  radial-gradient(circle at 0 0, rgba(204,0,0,0) 0, #fff4ed 0);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

.animate-pulse-fast {
  animation: pulse .7s cubic-bezier(0.5, 0, 0.6, 2) infinite;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 1rem;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #0000;
  border-right-color: white;
  position: relative;
  animation: l24 1s infinite linear;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.loader:after {
  animation-duration: 4s;
}
@keyframes l24 {
  100% {transform: rotate(1turn)}
}

/* HTML: <div class="loader"></div> */
.search_loader {
  width: fit-content;
  white-space: pre;
  font-size: 0.875rem;
  line-height: 1.2em;
  height:1.2em;
  overflow: hidden;
}
.search_loader:before {
  content:"Loading...\A👩‍🍳oading...\A👩‍🍳🍴ading...\A👩‍🍳🍴⏲️ding...\A👩‍🍳🍴⏲️🍲ing...\A👩‍🍳🍴⏲️🍲🍽️ng...\A👩‍🍳🍴⏲️🍲🍽️🧂g...\A👩‍🍳🍴⏲️🍲🍽️🧂🥢...\A👩‍🍳🍴⏲️🍲🍽️🧂🥢🥄..\A👩‍🍳🍴⏲️🍲🍽️🧂🥢🥄🔪.\A👩‍🍳🍴⏲️🍲🍽️🧂🥢🥄🔪👨‍🍳"; 
  white-space: pre;
  display: inline-block;
  animation: l39 1s infinite steps(11) alternate;
}

@keyframes l39 {
  100%{transform: translateY(-100%)}
}


.ant-select-selection-overflow {
  padding: 0.5rem;
  padding-right: 1.5rem;
}

.ant-select-arrow {
  pointer-events: visible !important;
  cursor: pointer !important;
}

.ant-select-clear {
  inset-inline-end: 40px !important;
}